import { options, renderRichText } from "twill-contentful-text-render";
import React, { FC } from "react";
import { Section } from "site-ui/src/components/Section";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

interface BlocksProps {
  blocks: Record<string, any>[];
  textOptions?: typeof options;
}

interface BlockProps extends Omit<BlocksProps, "blocks"> {
  id?: string;
  block: Record<string, any>;
}

const Block: FC<BlockProps> = ({ block, textOptions = options, id }) => {
  if (!block) return null;
  switch (block.__typename) {
    case "ContentfulBlockRichText":
      return (
        <Section
          align="center"
          maxWidth="md"
          id={id}
          textAlign={{ sm: "left", md: block.align }}
        >
          <Wrapper>
            {block.text && renderRichText(block.text, textOptions)}
          </Wrapper>
        </Section>
      );
    case "ContentfulQuote":
      return (
        <Section align="center" my={0} padding={0} maxWidth={"md"} id={id}>
          <Quote>{block.text && renderRichText(block.text, textOptions)}</Quote>
          <Typography>
            {block.author ? (
              <>
                &mdash; {block.author.firstName} {block.author.lastName},
                {block.author.jobTitle}
              </>
            ) : (
              <>
                &mdash; {block.fullName}, {block.jobTitle}
              </>
            )}
          </Typography>
        </Section>
      );
    default:
      return block.__typename;
  }
};

const Quote = styled.blockquote`
  max-width: 700px;
  margin: 0 auto 40px;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
  &:before,
  &:after {
    font-size: 300%;
    font-family: Arial, sans-serif;
    display: inline-block;
    line-height: 40px;
    height: 30px;
    vertical-align: bottom;
  }
  &:before {
    content: "“";
    margin-right: 0.1em;
  }
  &:after {
    content: "”";
  }
  @media (min-width: 640px) {
    * {
      font-size: 24px;
    }
  }
`;

const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  p {
    text-align: center;
    max-width: 788px;
    margin: 0 auto 1em;
  }
  p:not(.MuiTypography-h5) {
    text-align: left;
  }
`;

export const Blocks = ({ blocks, textOptions }: BlocksProps) => (
  <>
    {blocks &&
      blocks.map((block, key) => (
        <Block
          block={block}
          key={key}
          id={`section-${key + 1}`}
          textOptions={textOptions}
        />
      ))}
  </>
);
