import React from "react";
import Typography from "@material-ui/core/Typography";
import facebook from "./icons/facebook.svg";
import linkedin from "./icons/linkedin.svg";
import twitter from "./icons/twitter.svg";
import styled from "styled-components";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { Box } from "@material-ui/core";

export const Social = ({ text, mt = 0, url, header }) => {
  if (!url) return null;
  return (
    <Box mt={mt}>
      {header && <Typography variant="body1">{header}</Typography>}
      <IconsWrapper>
        <a
          target="_blank"
          onClick={() => {
            trackCustomEvent({
              category: "social share",
              action: "tw",
              label: url,
            });
          }}
          href={`https://twitter.com/intent/tweet?text=${text} ${url}`}
        >
          <img src={twitter} alt="Twitter" />
        </a>
        <a
          target="_blank"
          onClick={() => {
            trackCustomEvent({
              category: "social share",
              action: "linkedin",
              label: url,
            });
          }}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
        >
          <img src={linkedin} alt="Linkedin" />
        </a>
        <a
          target="_blank"
          onClick={() => {
            trackCustomEvent({
              category: "social share",
              action: "facebook",
              label: url,
            });
          }}
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        >
          <img src={facebook} alt="Facebook" />
        </a>
      </IconsWrapper>
    </Box>
  );
};

const IconsWrapper = styled.div`
  display: inline-flex;
  margin-top: 8px;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;
