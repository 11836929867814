import React from "react";
import { graphql } from "gatsby";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "twill-contentful-text-render";
import { HeadlineText } from "site-ui/src/components/HeadlineText";
import { Blocks } from "../../components/Blocks";
import { Section } from "site-ui/src/components/Section";
import { Box } from "@material-ui/core";
import { backgroundGrey } from "site-ui/colors";
import { Social } from "site-ui/src/components/Social";
import { CounterContainer } from "twill-contentful-text-render/src/components/CountersContainer";

export default ({ pageContext, data }) => {
  const {
    contentfulTestimonial: {
      title,
      subtitle,
      image: heroImage,
      text,
      variables,
      blocks,
    },
  } = data;
  return [
    <PageWrapper secondaryColor={backgroundGrey} fullHeight={true}>
      <Section textAlign="center">
        <HeadlineText
          headerText={<strong>{title}</strong>}
          subheader={subtitle && renderRichText(subtitle)}
        />
        <Social
          mt={4}
          header={"Share this story"}
          url={`https://www.twill.net/customer-stories/${pageContext.seoMeta.slug}`}
          text={renderRichText(subtitle)}
        />
      </Section>
      {heroImage?.localFile?.childImageSharp?.gatsbyImageData && (
        <GatsbyImage
          alt=""
          image={heroImage.localFile.childImageSharp.gatsbyImageData}
          imgStyle={{ objectFit: "cover", objectPosition: "right" }}
        />
      )}
      <Section>
        <Box mx="auto" mb={6} textAlign="center" maxWidth="780px">
          {renderRichText(text)}
        </Box>
        <CounterContainer variables={variables} />
      </Section>
    </PageWrapper>,

    <Box mb={15}>
      <Blocks blocks={blocks} />
    </Box>,
  ];
};

export const pageQuery = graphql`
  query TestimonialPageQuery(
    $locale: String
    $contentful_id: String
    $language: String
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulTestimonial(
      node_locale: { eq: $locale }
      contentful_id: { eq: $contentful_id }
    ) {
      title
      subtitle {
        raw
      }
      text {
        raw
      }
      jobTitle
      fullName
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
      variables {
        value
        label
        unit
      }
      blocks {
        __typename
        ...RichTextBlockFields
        ...QuoteBlockFields
      }
    }
  }
`;
